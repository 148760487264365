<template>
    <Modal width="550px" v-model:visible="visible" @close="handleClose" :mask-closable="false">
        <template #title>
            <p class="form-title">{{ t('user.addMember') }}</p>
        </template>
        <div>
            <Form ref="formDom" :model="formData" :rules="formRule" layout="vertical" autocomplete="off">
                <FormItem :hide-label="true" row-class="form-item-require" field="from">
                    <span class="form-item-title">{{ t('user.accountSource') }} </span>
                    <RadioGroup v-model.trim="formData.from">
                        <Radio value="email">{{ t('user.thisSystem') }}</Radio>
                        <Radio value="info">INFO</Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem field="email" :validate-trigger="['blur']" :label="t('user.email')">
                    <Input v-model.trim="formData.email" spellcheck="false" :placeholder="t('holder.enterHolder')" :maxlength="50" />
                </FormItem>
            </Form>
        </div>
        <template #footer>
            <Button class="btn-fill" type="primary" html-type="submit" @click="handleSubmit" :loading="loading">
                {{ t('ok') }}
            </Button>
        </template>
    </Modal>
</template>

<script>
import { defineComponent, ref, watch, reactive, inject } from 'vue'
import { Form } from '@arco-design/web-vue'
import { useRouter } from 'vue-router'
import * as reg from '@/utils/formReg'
export default defineComponent({
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        type: {
            type: String
        }
    },
    setup(props, ctx) {
        const t = inject('$t')
        const http = inject('$http')
        const loading = ref(false)
        const router = useRouter()
        const msg = inject('$message')
        const formDom = ref(null || Form)
        const visible = ref(props.showModal)
        watch(
            () => props.showModal,
            (val) => {
                visible.value = val
                if (!val) return
                formData.email = ''
            }
        )
        const formRule = {
            email: [
                { required: true, message: t('tip.emptyTip', { key: t('user.email') }) },
                { match: reg.emailReg, message: t('tip.emailRegTip1') },
                { maxLength: 50, message: t('tip.emailRegTip') },
                {
                    validator: (val, callback) => {
                        const paxReg = /paxsz\.com$/
                        if (formData.from === 'info' && !paxReg.test(val)) {
                            callback(t('tip.emailRegTip2'))
                        }
                        callback()
                    }
                }
            ]
        }
        const formData = reactive({
            from: 'email',
            email: ''
        })
        const handleClose = () => {
            loading.value = false
            formDom.value.resetFields()
            ctx.emit('closeModal')
        }
        const handleSubmit = () => {
            formDom.value.validate((errors) => {
                if (!errors) {
                    loading.value = true
                    const params = {
                        from: formData.from,
                        email: formData.email
                    }
                    http.account
                        .addMember(params)
                        .then(() => {
                            loading.value = false
                            msg.info(t('inviteSucc'))
                            if (props.type === 'inactive') {
                                ctx.emit('updateData', 'add')
                            } else {
                                router.push('/members/unconfirmed')
                                ctx.emit('closeModal')
                            }
                        })
                        .catch(() => {
                            loading.value = false
                        })
                }
            })
        }
        return {
            visible,
            formData,
            formRule,
            formDom,
            loading,
            handleSubmit,
            handleClose,
            t
        }
    }
})
</script>

<style lang="less" scoped>
.form-title {
    font-size: 18px;
    font-weight: 700;
    color: #666666;
}
.form-item-view {
    padding-left: 12px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    span:first-child {
        color: #4e5969;
    }
    span:last-child {
        text-align: right;
        width: 300px;
    }
}
.scroll-box {
    width: 506px;
    padding-right: 12px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
}
</style>
