<template>
    <Table
        :class="['user-table', { 'hide-page-list': conf.total <= conf.pageSizeOptions[0] }]"
        :data="conf.tableData"
        :pagination="conf"
        :scroll="{ y: conf.height }"
        :bordered="false"
        :stripe="true">
        <template #columns>
            <TableColumn :title="t('user.email')" data-index="email" ellipsis>
                <template #cell="{ record }">
                    <span :title="record.email">{{ record.email }}</span>
                </template>
            </TableColumn>
            <TableColumn :title="store.getters.isRoleType(Role.Admin) ? t('user.addTime') : t('invitationTime')" data-index="createTime">
                <template #cell="{ record }">
                    <span>{{ moment(record.createTime).format('YYYY-MM-DD HH:mm') }}</span>
                </template>
            </TableColumn>
            <TableColumn :title="t('user.accountSource')" data-index="from">
                <template #cell="{ record }">
                    <span :class="{ disable: record.status === 2 }">{{ record.sourceFrom === 'info' ? 'INFO' : t('user.thisSystem') }}</span>
                </template>
            </TableColumn>
            <TableColumn :title="t('operation')" :width="110">
                <template #cell="{ record }">
                    <Space :size="10">
                        <SvgIcon
                            v-if="store.getters.isRoleType(Role.Admin)"
                            name="resendemail"
                            :tip="t('user.sendEmail')"
                            color="#2b32c9"
                            hoverColor="#2bc954"
                            :size="22"
                            @click="sendEmailFunc(record)" />
                        <SvgIcon name="delete" :tip="t('delete')" color="#2b32c9" hoverColor="#2bc954" :size="20" @click="deleteFunc(record)" />
                    </Space>
                </template>
            </TableColumn>
        </template>
    </Table>
</template>

<script>
import { defineComponent, inject } from 'vue'
import { Role } from '@/store'
import { useStore } from 'vuex'
export default defineComponent({
    props: {
        data: {
            type: Array,
            default: () => []
        },
        conf: {
            type: Object
        }
    },
    setup(props, ctx) {
        const t = inject('$t')
        const http = inject('$http')
        const moment = inject('$moment')
        const modal = inject('$modal')
        const msg = inject('$message')
        const store = useStore()
        const sendEmailFunc = (item) => {
            modal.warning({
                hideCancel: false,
                maskClosable: false,
                title: t('prompt'),
                okText: t('ok'),
                cancelText: t('cancel'),
                content: t('tip.sendEmailTip'),
                onOk: () => {
                    http.account.resendActivationEmail({ email: item.email, from: item.sourceFrom }).then(() => {
                        msg.info(t('tip.sendEmailSuccessTip'))
                    })
                }
            })
        }
        const deleteFunc = (item) => {
            const isAdmin = store.getters.isRoleType(Role.Admin)
            modal.warning({
                hideCancel: false,
                maskClosable: false,
                title: t('prompt'),
                okText: t('ok'),
                cancelText: t('cancel'),
                content: t(isAdmin ? 'tip.deleteUserTip' : 'tip.removeUserTip', { name: item.email }),
                onOk: () => {
                    const isAdmin = store.getters.isRoleType(Role.Admin)
                    const api = isAdmin ? http.account.deleteUser(item.id) : http.account.deleteMember(store.state.userData.teamId, item.id)
                    api.then(() => {
                        ctx.emit('updateData', 'delete')
                        msg.info(isAdmin ? t('delSucc') : t('removeSucc'))
                    })
                }
            })
        }
        return {
            sendEmailFunc,
            deleteFunc,
            moment,
            store,
            Role,
            t
        }
    }
})
</script>
