<template>
    <div class="contain-box full-height">
        <CardBox>
            <template #tool-title>
                <div class="tab-bar">
                    <template v-for="item in tabMap">
                        <span
                            :class="{ active: route.path === item.url }"
                            :key="item.title"
                            v-if="store.getters.hasViewPermission(item.url)"
                            @click="router.push(item.url)">
                            {{ $t(item.title) }}
                        </span>
                    </template>
                </div>
            </template>
            <template #tool-bar>
                <Space :size="20">
                    <Search
                        @search="search"
                        :resetVal="resetVal"
                        :placeholder="pageConf.currentRoute === 'inactive' ? t('login.email') : t('login.username')"
                        :width="210" />
                    <Select
                        :style="{ width: '120px' }"
                        v-model="pageConf.from"
                        size="large"
                        @change="changeOption"
                        :placeholder="t('user.accountSource')"
                        allow-clear>
                        <Option value="info">INFO</Option>
                        <Option value="email">{{ t('user.thisSystem') }}</Option>
                    </Select>
                    <Button class="btn-fill" type="primary" size="large" v-if="!store.getters.isRoleType(Role.Admin)" @click="showMemberModal = true">
                        {{ t('user.addMember') }}
                    </Button>
                </Space>
            </template>
            <template #contain>
                <ActiveUserTable :conf="pageConf" v-if="pageConf.currentRoute === 'active'" @updateData="getData" />
                <InactiveUserTable :conf="pageConf" v-if="pageConf.currentRoute === 'inactive'" @updateData="getData" />
            </template>
        </CardBox>
        <AddMemberModal
            v-if="store.getters.isRoleType(Role.TeamOwner)"
            :showModal="showMemberModal"
            :type="pageConf.currentRoute"
            @closeModal="showMemberModal = false"
            @updateData="changeOption" />
    </div>
</template>

<script>
import CardBox from '@/components/CardBox.vue'
import Search from '@/components/Search.vue'
import AddMemberModal from '@/components/AddMemberModal.vue'
import ActiveUserTable from './ActiveUserTable.vue'
import InactiveUserTable from './InactiveUserTable.vue'
import useWindowResize from '@/hooks/useWindowResize'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Role } from '@/store'
import { defineComponent, reactive, ref, inject, watch } from 'vue'
export default defineComponent({
    components: {
        ActiveUserTable,
        InactiveUserTable,
        CardBox,
        Search,
        AddMemberModal
    },
    setup() {
        const t = inject('$t')
        const http = inject('$http')
        const winData = useWindowResize()
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const showMemberModal = ref(false)
        const resetVal = ref(true)
        const tabMap = store.getters.isRoleType(Role.Admin) ? store.state.navMap[2].child : store.state.navMap[3].child
        watch(
            () => winData.height,
            (val) => {
                pageConf.height = val - 360
            }
        )
        const typeMap = {
            inactive: 'inactive',
            active: 'active',
            confirmed: 'active',
            unconfirmed: 'inactive'
        }
        const pageConf = reactive({
            from: '',
            username: '',
            tableData: [],
            currentRoute: typeMap[route.params.type],
            showTotal: true,
            showPageSize: true,
            hideOnSinglePage: false,
            pageSizeOptions: [30, 60, 100],
            current: 1,
            pageSize: 30,
            total: 0,
            height: 570,
            onChange: (data) => {
                pageConf.current = data
                getData()
            },
            onPageSizeChange: (data) => {
                pageConf.current = 1
                pageConf.pageSize = data
                getData()
            }
        })
        const search = (val) => {
            pageConf.current = 1
            pageConf.username = val
            getData()
        }
        const changeOption = () => {
            pageConf.current = 1
            showMemberModal.value = false
            getData()
        }
        const getData = () => {
            if (store.getters.isRoleType(Role.Admin)) {
                const params = {
                    page: pageConf.current - 1,
                    size: pageConf.pageSize,
                    username: pageConf.username,
                    from: pageConf.from,
                    activated: pageConf.currentRoute === 'active'
                }
                http.account.getUsers(params).then(({ data }) => {
                    pageConf.tableData = data.data
                    pageConf.total = data.total
                    document.querySelector('.arco-table-body').scrollTop = 0
                })
            } else {
                const params = {
                    page: pageConf.current - 1,
                    size: pageConf.pageSize,
                    username: pageConf.username,
                    from: pageConf.from,
                    activated: pageConf.currentRoute === 'active',
                    teamId: store.state.userData.teamId
                }
                http.account.getMembers(params).then(({ data }) => {
                    pageConf.tableData = data.data
                    pageConf.total = data.total
                    document.querySelector('.arco-table-body').scrollTop = 0
                })
            }
        }
        watch(
            () => route.params,
            (val) => {
                if (val.type) {
                    pageConf.currentRoute = typeMap[val.type]
                    pageConf.from = ''
                    pageConf.username = ''
                    pageConf.tableData = []
                    pageConf.current = 1
                    pageConf.pageSize = 30
                    resetVal.value = !resetVal.value
                    getData()
                }
            },
            { immediate: true }
        )

        return {
            showMemberModal,
            pageConf,
            resetVal,
            Role,
            store,
            router,
            route,
            t,
            search,
            getData,
            changeOption,
            tabMap
        }
    }
})
</script>

<style lang="less" scoped>
.contain-box {
    .user-table {
        padding: 0 60px;
        margin-top: 30px;
    }
}
</style>
