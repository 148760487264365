<template>
    <Table
        :class="['user-table', { 'hide-page-list': conf.total <= conf.pageSizeOptions[0] }]"
        :pagination="conf"
        :data="conf.tableData"
        :scroll="{ y: conf.height }"
        :bordered="false"
        :stripe="true">
        <template #columns>
            <TableColumn :title="t('user.name')" data-index="username" :width="210">
                <template #cell="{ record }">
                    <span :class="{ disable: record.status === 2 }">{{ record.username }}</span>
                </template>
            </TableColumn>
            <TableColumn :title="t('user.email')" data-index="email" ellipsis>
                <template #cell="{ record }">
                    <span :class="{ disable: record.status === 2 }" :title="record.email">{{ record.email }}</span>
                </template>
            </TableColumn>
            <TableColumn :title="t('user.phone')" data-index="phone">
                <template #cell="{ record }">
                    <span :class="{ disable: record.status === 2 }">{{ record.phone || '-' }}</span>
                </template>
            </TableColumn>
            <TableColumn :title="store.getters.isRoleType(Role.Admin) ? t('user.addTime') : t('invitationTime')" data-index="createTime">
                <template #cell="{ record }">
                    <span :class="{ disable: record.status === 2 }">{{ moment(record.createTime).format('YYYY-MM-DD HH:mm') }}</span>
                </template>
            </TableColumn>
            <TableColumn :title="t('user.accountSource')" data-index="from">
                <template #cell="{ record }">
                    <span :class="{ disable: record.status === 2 }">{{ record.sourceFrom === 'info' ? 'INFO' : t('user.thisSystem') }}</span>
                </template>
            </TableColumn>
            <TableColumn :title="t('operation')" :width="110">
                <template #cell="{ record }">
                    <Space :size="10">
                        <Tooltip :content="`${t('enable')} / ${t('disable')}`" v-if="store.getters.isRoleType(Role.Admin)">
                            <Switch v-model="record.status" type="line" :checked-value="1" :unchecked-value="2" @click="switchFunc(record)" />
                        </Tooltip>
                        <SvgIcon
                            name="delete"
                            v-if="record.delete !== false"
                            :tip="t('delete')"
                            color="#2b32c9"
                            hoverColor="#2bc954"
                            :size="20"
                            @click="deleteFunc(record)" />
                    </Space>
                </template>
            </TableColumn>
        </template>
    </Table>
</template>

<script>
import { defineComponent, inject, getCurrentInstance } from 'vue'
import { Role } from '@/store'
import { useStore } from 'vuex'
export default defineComponent({
    props: {
        conf: {
            type: Object
        }
    },
    setup(props, ctx) {
        const t = inject('$t')
        const http = inject('$http')
        const moment = inject('$moment')
        const msg = inject('$message')
        const modal = inject('$modal')
        const store = useStore()
        const { proxy } = getCurrentInstance()
        const switchFunc = (item) => {
            if (item.status === 2) {
                modal.warning({
                    hideCancel: false,
                    maskClosable: false,
                    title: t('prompt'),
                    okText: t('ok'),
                    cancelText: t('cancel'),
                    content: t('tip.disableUserTip'),
                    onCancel: () => {
                        item.status = 1
                        proxy.$forceUpdate()
                    },
                    onOk: () => {
                        setStatus(item.id, 2, 1)
                    }
                })
            } else {
                setStatus(item.id, 1, 2)
            }
            function setStatus(id, status, originStatus) {
                http.account
                    .modifyUser({ id, status })
                    .then(() => {
                        msg.info(t('oparationSucc'))
                        proxy.$forceUpdate()
                    })
                    .catch(() => {
                        item.status = originStatus
                        proxy.$forceUpdate()
                    })
            }
        }
        const deleteFunc = (item) => {
            const isAdmin = store.getters.isRoleType(Role.Admin)
            modal.warning({
                hideCancel: false,
                maskClosable: false,
                title: t('prompt'),
                okText: t('ok'),
                cancelText: t('cancel'),
                content: t(isAdmin ? 'tip.deleteUserTip' : 'tip.removeUserTip', { name: item.username }),
                onOk: () => {
                    const api = isAdmin ? http.account.deleteUser(item.id) : http.account.deleteMember(store.state.userData.teamId, item.id)
                    api.then(() => {
                        ctx.emit('updateData', 'delete')
                        msg.info(isAdmin ? t('delSucc') : t('removeSucc'))
                    })
                }
            })
        }
        return {
            deleteFunc,
            switchFunc,
            t,
            Role,
            store,
            moment
        }
    }
})
</script>

<style lang="less" scoped>
.user-table {
    .disable {
        color: #999;
    }
}
</style>
